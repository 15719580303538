@import "normalize.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  margin: 0px 16px;
  font-size: 40px;
}

h2 {
  margin: 0px 16px;
  font-size: 36px;
}

h3 {
  margin: 0px 16px;
  font-size: 32px;
}

h4 {
  margin: 0px 16px;
  font-size: 24px;
}

h5 {
  margin: 0px 16px;
  font-size: 20px;
}

h6 {
  margin: 0px 16px;
  font-size: 16px;
}

input {
  margin: 0;
  border: 0;
  padding: 0;

  height: 2.5em;

  border-bottom: 1px solid black;
  margin-right: 0.5em;
  text-align: right;
}

input:focus {
  outline: 0;
}

button.pressable {
  cursor: pointer;
  border: 0;
  background: none;
  font-size: 1em;
}